import React, { useState, useEffect } from "react";
import logo from "./logo.svg";
import { SwitchTransition, CSSTransition } from "react-transition-group";
// import {
//     BrowserRouter as Router,
//     Switch,
//     Route,
//     Link,
//     useRouteMatch,
//     useHistory
//   } from "react-router-dom";
import "./App.css";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
// import * from '@m'
import Landing from "./pages/Landing";
import Home from "./pages/Home";
import About from "./pages/About";
import Artists from "./pages/Artists";
import Contact from "./pages/Contact";
import Nav from "./components/Nav";
import Photography from "./pages/Photography";
import Tour from "./pages/Tour";
import Inspiration from "./pages/Inspiration";

const theme = createMuiTheme({
  palette: {
    test: "blue",
  },
  colors: {
    blue: "#002366",
    lightBlue: "#124AB3",
    brown: "#401A00",
    lightBrown: "#bd7748",
    golden: "#B37A00",
    light: "#ffffff",
  },
  hiddenComponent: {
    display: "none",
  },
  breakpoints: {
    values: {
      xs: 321,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
    },
  },
});

function App() {
  const [currentPage, setCurrentPage] = useState();
  const setPage = (page) => {
    setCurrentPage(page);
  };
  // const history = useHistory();

  const getCurrentPage = () => {
    switch (currentPage) {
      case "home":
        return <Home />;
      // break;
      case "about":
        return <About />;
      // break;
      case "artists":
        return <Artists />;
      // break;
      case "contact":
        return <Contact />;
      case "don":
        return <Photography />;
      case "tour":
        return <Tour />;
      case "inspiration":
        return <Inspiration />;
      // break;
    }
  };

  return (
    <MuiThemeProvider theme={theme}>
      <div className="App">
        <Nav navigate={setPage} />
        {getCurrentPage()}
      </div>
    </MuiThemeProvider>
  );
}

export default App;
