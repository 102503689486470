import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function LeftArrow(props) {
    return (
        <SvgIcon {...props} >
          <path d="M25 0 L25 20 L5 50 L25 80 L25 100 L0 50 Z" />
        </SvgIcon>

    )
}
