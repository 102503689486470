import React from "react";
import ImageDisplay from "./ImageDisplay";

export default function Sports(props) {
  return (
    <ImageDisplay
      imageCount={33}
      numberOfImagesToDisplay={props.numberOfImagesToDisplay}
      componentName={"sports"}
    />
  );
}
