import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { gsap, TimelineLite, Power1, Expo } from "gsap";
import ImageDisplay from "./ImageDisplay";

export default function History(props) {
  return (
    <ImageDisplay
      imageCount={33}
      numberOfImagesToDisplay={props.numberOfImagesToDisplay}
      componentName={"history"}
    />
  );
}
