import React from "react";
import ImageDisplay from "./ImageDisplay";

export default function Awards(props) {
  return (
    <ImageDisplay
      imageCount={20}
      numberOfImagesToDisplay={props.numberOfImagesToDisplay}
      componentName={"awards"}
    />
  );
}
