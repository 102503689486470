import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';
export default function RightArrow(props) {
    return (
        <SvgIcon {...props} >
            <path d="M0 0 L25 50 L0 100 L0 80 L20 50 L0 20 Z" />
  {/* <polygon points="0,50 25,0 25,20 5,50 25,80 25,100 0,50" style={{fill:'#ccc',stroke:'white',strokeWidth:2}}/> */}

</SvgIcon>

    )
}


