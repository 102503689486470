import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import { gsap, TimelineLite, Power1, Expo } from "gsap";

const useStyles = makeStyles((theme) => ({
  imageContainer: {
    background: "#eee",
    borderTop: "1px solid #aaa",
    borderBottom: "1px solid #aaa",
    height: "60vh",
    display: "flex",
    flexDirection: "row",
    gap: "4rem",
    paddingLeft: "5%",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.between("lg", "xl")]: {
      // top: "15vh",
    },
    [theme.breakpoints.between("md", "lg")]: {
      top: "5vh",
    },
    [theme.breakpoints.down("sm")]: {
      top: "0vh",
      height: "50vh",
    },

    [`${theme.breakpoints.down("sm")} and (orientation:landscape)`]: {
      top: "5vh",
      height: "50vh",
    },

    // position: "absolute",
  },
  image: {
    width: "20%",
    height: "auto",
    position: "absolute",
    // left: "90%",
    opacity: 0,
    [theme.breakpoints.between("md", "lg")]: {
      width: "20%",
    },
    [`${theme.breakpoints.down("sm")} and (orientation:portrait)`]: {
      width: "50%",
    },
    [`${theme.breakpoints.down("sm")} and (orientation:landscape)`]: {
      width: "20%",
    },
    [`${theme.breakpoints.up("sm")} and (orientation:portrait)`]: {
      width: "27%",
    },
  },
}));

export default function ImageDisplay(props) {
  const classes = useStyles();
  const [animating, setAnimating] = useState(false);
  const [currentState, setState] = useState({ imageIndexes: [], ready: false });
  const componentImageCount = props.imageCount;
  let timeOutVal;
  let delay = 3;
  let leftOffset = 5;
  let leftMulti = 25;
  switch (props.numberOfImagesToDisplay) {
    case 1:
      timeOutVal = 5000;
      delay = 0;
      leftOffset = 20;
      break;
    case 3:
      timeOutVal = 9000;
      leftOffset = 5;
      leftMulti = 30;
      break;
    case 4:
      timeOutVal = 11000;
      break;
  }
  const generateIndeces = (start) => {
    //   if(start)
    let imageIndeces = [];
    const max = start
      ? start + props.numberOfImagesToDisplay
      : props.numberOfImagesToDisplay;
    const min = start ? start + 1 : 1;
    let imageNum;
    for (let i = min; i < max + 1; i++) {
      imageNum = i <= componentImageCount ? i : i - componentImageCount;
      imageIndeces.push(imageNum);
    }
    console.log(imageIndeces);
    setState((currentState) => ({
      ...currentState,
      imageIndexes: imageIndeces,
      ready: true,
    }));
  };

  useEffect(() => {
    generateIndeces();
  }, []);

  useEffect(() => {
    if (currentState.imageIndexes.length > 0) {
      setAnimating(true);
      let images = gsap.utils.toArray("[id*=displayImage]");
      let count = 0;
      images.forEach((image) => {
        let t1 = new TimelineLite();
        t1.to(image, {
          opacity: 1,
          left: leftMulti * count + leftOffset + "%",
          duration: 2.5,
          ease: Power1.easeInOut,
          delay: count,
        }).to(image, {
          opacity: 0,
          left: 25 * count - 20 + "%",
          duration: 2.5,
          easing: Power1.easeInOut,
          delay: delay,
        });
        count += 1;
      });
      setTimeout(() => {
        // setAnimating(false);
        generateIndeces(
          currentState.imageIndexes[currentState.imageIndexes.length - 1]
        );
      }, timeOutVal);
    }
  }, [currentState.imageIndexes]);
  return (
    <div className={classes.imageContainer}>
      {currentState.imageIndexes.map((imageNumber, index) => {
        return (
          <img
            className={classNames(classes.image)}
            id={"displayImage_" + imageNumber}
            key={"displayImage_" + imageNumber}
            style={{ left: index * 20 + 30 + "%" }}
            src={require("../static/images/lightbox/" +
              props.componentName +
              "/" +
              props.componentName +
              "_" +
              imageNumber +
              ".jpg")}
          ></img>
        );
      })}
    </div>
  );
}
