import React from "react";
import ImageDisplay from "./ImageDisplay";

export default function Memories(props) {
  return (
    <ImageDisplay
      imageCount={17}
      numberOfImagesToDisplay={props.numberOfImagesToDisplay}
      componentName={"memories"}
    />
  );
}
