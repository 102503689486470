import React from 'react';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
    underline: {
        position: 'absolute',
        background: theme.colors.light,
        height: 3,
        top: 70,
        [theme.breakpoints.down('sm')]:{
            display:'none'
        }
    }
}))
export default function Underline(props) {
    const classes = useStyles();
    return (
        <div className={classes.underline} style={{ width: props.lineWidth, transition: 'all .3s ease-out', left: props.lineLeft }}>

        </div>
    )
}
