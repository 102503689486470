import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Modal from '@material-ui/core/Modal'
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

const useStyles = makeStyles(theme => ({
    contentContainer: {
       width:'60vw',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
            fontSize: '0.8em'
        }
    },
    mainText: {
        paddingTop: '20vh',
    },
    videoTour: {
        position: 'absolute',
        top: '20vh',
        right: '1vw',
        height: 100,
        width: 300,
        border: '2px solid ' + theme.colors.blue,
        borderRadius: 5,
        padding: 10,
        background: theme.colors.light,
        cursor: 'pointer',
        "& p": {
            fontWeight: 'bold'
        },
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            top: '18vh',
            width: 'auto',
            height: 'auto',
            fontSize: '0.7em',
            margin: '0 auto'
        }
    },
    cards: {
        display: 'flex',
        flexDirection: 'column'
    },
    card: {
        width: '100%',
        border: '1px solid',
        borderColor: theme.colors.blue,
        borderRadius: 10,
        marginBottom: 20,
        boxShadow: '9px 10px 14px 3px rgba(177,179,194,0.2)',
        opacity: 0,
        marginTop: 40,
        overflow: 'hidden',
        marginRight: 20,
        display: 'flex',
        "& ul": {
            listStyleType: 'none',
            textAlign: 'left',
            "& li": {}
        }
    },
    cardHeading: {
        background: '#ddd',
        borderRight: '1px solid ' + theme.colors.blue,
        padding: 20,
        minWidth: '30%',
        maxWidth: '30%',
        fontWeight: 'bold'
    },
    cardContent: {
        padding: 20,
        "& ul": {
            textAlign: 'left',
            listStyleType: 'none',
            "& li::before": {
                content: '"✽ "',
                color: theme.colors.blue,
                fontSize: '.7em',
                marginTop: '4px',
                position: 'absolute',

            },
            "& li > span": {
                position: 'relative',
                left: 20,
                fontSize: '0.9em'
            }
        },
       
        [theme.breakpoints.down('sm')]: {
            "& ul": {
                marginLeft: 0,
                padding: 0,
            }
        }

    },
    secondaryList:{
        "& li::before":{
            content:'" " !important'
        }
        
    },
    cardOne: {
        opacity: 1,
        left: '1vw !important',
        transition: 'all 1.5s 1s ease',
    },
    cardTwo: {
        opacity: 1,
        left: '1vw !important',
        transition: 'all 1.5s 1.5s ease'
    },
    cardThree: {
        opacity: 1,
        transition: 'all 1.5s 2s ease'
    },
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    paper: {
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),
        [theme.breakpoints.down('md')]:{
            padding:0,
            boxShadow:'none',
            height:400,
            width:325,
            textAlign:'center',
            "& h2":{
                fontSize:'1em'
            },
        }
    },
    tourFrame:{
        height:600,
         width:800,
         [theme.breakpoints.down('md')]:{
             height:300,
             width:300
         }
    }
}))
export default function About() {
    const classes = useStyles();
    const [cardsVisible, setCardsVisible] = useState(false)
    const [open, setOpen] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCardsVisible(true)
        }, 0);
        return () => {
            clearTimeout(timeout)
        }
    }, []);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    }
    return (
        <div >
            <div className={classes.contentContainer}>
                {/* <div className={classes.videoTour} onClick={handleOpen}>
                    <p>Click here for a tour of the Corby Fine Framing Studio!</p>
                </div> */}
                <div className={classes.mainText}>
                    Corby Custom Framing Owner Don Corby  has been in the framing business for 30 years. Motivation to start his business was to frame his own photography as inexpensively as possible. Today he passes that same consideration of cost to his customers.
            </div>
                <div className={classes.cards}>
                    <div className={classNames(classes.card, cardsVisible ? classes.cardOne : '')}>
                        <div className={classes.cardHeading}>
                            Custom Picture Framing is our business. All frames are made on site for a fast turnaround time.
                    </div>
                        <div className={classes.cardContent}>
                            <p>We make custom frames for original art in all mediums including:</p>
                            <p>Oils, Acrylics, Watercolour, Photography, Pastel, and Paper Tole
                        </p>
                        </div>
                    </div>
                    <div className={classNames(classes.card, cardsVisible ? classes.cardTwo : '')}>
                        <div className={classes.cardHeading}>
                            Photo framing
                    </div>
                        <div className={classes.cardContent}>
                            We create collage or customizable multi photo frames, 9×6, 12×8, 10×12, 12×18 or any other custom size frame.  Square photo frames, also available.
                    </div>
                    </div>
                    <div className={classNames(classes.card, cardsVisible ? classes.cardThree : '')}>
                        <div className={classes.cardHeading}>
                            We also make frames for any arts and crafts, memorbilia, or almost anything you can imagine:
                    </div>
                        <div className={classes.cardContent}>
                            Some examples of past framing we've done are:
                        <ul>
                                <li><span>Needlework</span></li>
                                <li><span>Posters</span></li>
                                <li><span>Limited Editions artwork</span></li>
                                <li><span>Mirrors</span></li>
                                <li><span>Shadow and Memory box frames for:
                                <ul className={classes.secondaryList}>
                                        <li><span>Sports Jerseys</span></li>
                                        <li><span>War medals</span></li>
                                        <li><span>Antique collectibles such as watches, keys, spoons, etc.</span></li>
                                    </ul>
                                </span></li>

                                <li><span>Other items Don has made Box frames for are ballet shoes, golf club, fossils, and antique engineering tools.</span></li>
                            </ul>
                        </div>
                    </div>
                    <div className={classNames(classes.card, cardsVisible ? classes.cardThree : '')}>

                        <div className={classes.cardContent}>
                            <p>We also offer personalized or custom engraved plates.</p>
                            <p>We ship custom frames worldwide.  </p>
                            <p>Anything you wish to preserve Don can find an attractive way to frame it. </p>
                            <p>We have a large Selection of Wood Frames and Metal Frames</p>

                        </div>
                    </div>
                </div>
            </div>
            <Modal
                aria-labelledby="transition-modal-title"
                aria-describedby="transition-modal-description"
                className={classes.modal}
                open={open}
                onClose={handleClose}
                closeAfterTransition
                BackdropComponent={Backdrop}
                BackdropProps={{
                    timeout: 500,
                }}
            >
                <Fade in={open}>
                    <div className={classes.paper}>
                        <h2 id="transition-modal-title">Corby framing studio</h2>
                        <p id="transition-modal-description">
                            <code>
                                <iframe src="https://www.google.com/maps/embed?pb=!4v1588592719885!6m8!1m7!1sCAoSLEFGMVFpcE11ay1mWTY3REdHcUlEOWd0ZWh6NWVEdVpkNTlybG4zWkFpUWlv!2m2!1d43.37778962586795!2d-80.00205275689353!3f131.63928549044172!4f-6.997982203243453!5f0.7820865974627469" className={classes.tourFrame}></iframe>
                            </code>
                        </p>
                    </div>
                </Fade>
            </Modal>
        </div>
    )
}
