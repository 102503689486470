import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames'

const useStyles = makeStyles(theme => ({
    contentContainer: {
        width: '60vw',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        margin: '0 auto',
        [theme.breakpoints.down('sm')]:{
            fontSize:'0.8em',
            width:'90vw'
        }
    },
    mainText: {
        paddingTop: '20vh', 
    },
    cards: {
        display: 'flex',
        flexDirection: 'column'
    },
    card: {
        width: '100%',
        border: '1px solid',
        borderColor: theme.colors.blue,
        borderRadius: 10,
        marginBottom: 20,
        boxShadow: '9px 10px 14px 3px rgba(177,179,194,0.2)',
        opacity: 0,
        marginTop: 40,
        overflow: 'hidden',
        marginRight: 20,
        display: 'flex',
        "& ul": {
            listStyleType: 'none',
            textAlign: 'left',
            margin: 0,
            padding: 0,
            "& li::before": {
                content: '"✽ "',
                color: theme.colors.blue,
                fontSize: '.7em',
                marginTop: '4px',
                position: 'absolute',

            },
            "& li > span": {
                position: 'relative',
                left: 20,
                fontSize:'0.9em'
            }
        }
    },
    cardHeading: {
        background: '#ddd',
        borderRight: '1px solid ' + theme.colors.blue,
        padding: 20,
        minWidth: '30%',
        maxWidth: '30%',
        fontWeight: 'bold'
    },
    cardContent: {
        padding: 20
    },
    cardOne: {
        opacity: 1,
        left: '1vw !important',
        transition: 'all 1.5s 1s ease',
    },
    cardTwo: {
        opacity: 1,
        left: '1vw !important',
        transition: 'all 1.5s 1.5s ease'
    },
    cardThree: {
        opacity: 1,
        transition: 'all 1.5s 2s ease'
    }
}))

export default function Artists() {
    const classes = useStyles()
    const [cardsVisible, setCardsVisible] = useState(false)

    useEffect(() => {
        const timeout = setTimeout(() => {
            setCardsVisible(true)
        }, 0);
        return () => {
            clearTimeout(timeout)
        }
    }, [])
    return (
        <div className={classes.contentContainer}>
            <div className={classes.mainText}>
                <p>Corby Custom Framing offers a relaxed consultation style for Artists.</p>
                <p>
                    Don will make suggestions for framing, but he also listens to what the artist wants, while providing them the most value for their dollar. He started framing
            to display his own photography, and believes that framing should take your eye into the artwork, enhancing it rather than overshadowing it.</p>
                <p>
                    Because Don buys  moulding in bulk quantities Corby Custom Framing can reduce the cost on many popular moulding profiles. Maintaining a 2000+ ft
                    stock of moulding at all times ensures a fast turnaround.
                </p>
            </div>
            <div className={classes.cards}>
                <div className={classNames(classes.card, cardsVisible ? classes.cardOne : '')}>
                    <div className={classes.cardHeading}>
                        Available for artists
                    </div>
                    <div className={classes.cardContent}>
                        We make custom frames for original art in all mediums including:
                        <ul>
                            <li><span>discounted pricing for lot framing for shows</span></li>
                            <li><span>Mats</span></li>
                            <li><span>Conservation Grade Mat boards – museum quality – acid free, lignin free</span></li>
                            <li><span>100%  Cotton Rag Mat</span></li>
                        </ul>
                    </div>
                </div>
                <div className={classNames(classes.card, cardsVisible ? classes.cardTwo : '')}>
                    <div className={classes.cardHeading}>
                        Glass
                    </div>
                    <div className={classes.cardContent}>
                        The options available for glass are as follows:
                        <ul>
                            <li><span>Regular</span></li>
                            <li><span>Non glare</span></li>
                            <li><span>UV</span></li>
                            <li><span>Tru-vu ultra-vu</span></li>
                            <li><span>Art glass</span></li>
                        </ul>
                    </div>
                </div>
                <div className={classNames(classes.card, cardsVisible ? classes.cardThree : '')}>
                    <div className={classes.cardHeading}>
                        Other Services
                    </div>
                    <div className={classes.cardContent}>
                        <ul>


                            <li><span>Archival shrink wrapping</span></li>
                            <li><span>Promotes Artists work through a yearly art show, and wall space in the gallery.</span></li>
                            <li><span>Artist referral service for customers looking for commissioned Artwork</span></li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}
