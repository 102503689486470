import React, { useEffect, useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Underline from "./Underline";
import Logo from "./Logo";
import { IconButton, Menu, MenuItem } from "@material-ui/core";
import MenuIcon from "@material-ui/icons/Menu";
import {
  Link,
  Element,
  Events,
  animateScroll as scroll,
  scroller,
  scrollTo,
} from "react-scroll";
// import FBIcon from '../static/images/icons/Icon_awesome_facebook@2x.png'
import FacebookIcon from "@material-ui/icons/Facebook";
import EmailIcon from "@material-ui/icons/Email";
import classNames from "classnames";
const useStyles = makeStyles((theme) => ({
  navBar: {
    background: theme.colors.blue,
    width: "100%",
    height: 100,
    display: "flex",
    justifyContent: "center",
    position: "fixed",
    zIndex: 30,
    top: 0,
    maxWidth: "100%",

    [theme.breakpoints.down("sm")]: {
      height: 50,
    },
    [`${theme.breakpoints.down("sm")} and (orientation:landscape)`]: {
      // width: "75%",
    },
  },
  mobileMenu: {
    color: theme.colors.light,
    position: "absolute",
    top: 10,
    left: 10,
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "block",
    },
  },
  logo: {
    display: "none",
  },
  navBarLogo: {
    height: 90,
    width: 90,
    [theme.breakpoints.down("sm")]: {
      height: 40,
      width: 40,
    },
  },
  logoSmall: {
    position: "absolute",
    top: 5,
    left: 5,
    [theme.breakpoints.down("sm")]: {
      left: "40%",
    },
  },
  link: {
    color: theme.colors.light,
    marginRight: 20,
    marginTop: 40,
    fontSize: 20,
    fontWeight: "normal",
    cursor: "pointer",
    height: "1.5rem",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  underLine: {
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  home: {},
  about: {},
  facebookLink: {
    position: "fixed",
    top: 25,
    right: 80,
    "& a": {
      color: theme.colors.light,
    },
    [theme.breakpoints.down("sm")]: {
      top: 5,
      right: 50,
    },
    [`${theme.breakpoints.down("sm")} and (orientation:landscape)`]: {
      top: 5,
      right: 200,
    },
  },
  emailLink: {
    position: "fixed",
    top: 25,
    right: 20,
    "& a": {
      color: theme.colors.light,
    },
    [theme.breakpoints.down("sm")]: {
      top: 5,
      right: 10,
    },
    [`${theme.breakpoints.down("sm")} and (orientation:landscape)`]: {
      top: 5,
      right: 150,
    },
  },
  FBIcon: {
    height: 30,
  },
  active: { fontWeight: "bold", borderBottom: "2px solid" },
}));
export default function Nav(props) {
  const classes = useStyles();
  const viewportWidthRatio = window.innerWidth / 1920;
  const [logoSize, setLogoSize] = useState(300);
  const [anchorEl, setAnchorEl] = useState(null);
  const [pages, setPages] = useState([
    { name: "home" },
    { name: "about" },
    { name: "artists" },
    { name: "contact" },
    { name: "don" },
    { name: "tour" },
    { name: "inspiration" },
  ]);

  const [currentPage, setCurrentPage] = useState(0);

  const setPage = (pageNumber) => {
    setCurrentPage(pageNumber);
    setAnchorEl(null);
    scroll.scrollToTop({ smooth: true, duration: 500 });
  };

  useEffect(() => {
    props.navigate(pages[currentPage].name);

    return () => null;
  }, [currentPage]);

  const handleClose = () => {
    setAnchorEl(null);
  };
  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };
  return (
    <div className={classes.navBar}>
      <div
        className={currentPage === 0 ? classes.logo : classes.logoSmall}
        onClick={() => setPage(0)}
      >
        <Logo
          viewBox={"0 0 300 300"}
          style={{ color: currentPage === 0 ? "#002366" : "#fff" }}
          className={classes.navBarLogo}
        />
      </div>
      <div
        className={classNames(
          classes.link,
          currentPage === 0 ? classes.active : ""
        )}
        onClick={() => setPage(0)}
        id="home"
      >
        Home
      </div>
      <div
        className={classNames(
          classes.link,
          currentPage === 1 ? classes.active : ""
        )}
        onClick={() => setPage(1)}
        id="about"
      >
        About
      </div>
      <div
        className={classNames(
          classes.link,
          currentPage === 2 ? classes.active : ""
        )}
        onClick={() => setPage(2)}
        id="artists"
      >
        For Artists
      </div>
      <div
        className={classNames(
          classes.link,
          currentPage === 6 ? classes.active : ""
        )}
        onClick={() => setPage(6)}
        id="inspiration"
      >
        Inspiration
      </div>
      <div
        className={classNames(
          classes.link,
          currentPage === 5 ? classes.active : ""
        )}
        onClick={() => setPage(5)}
        id="tour"
      >
        Virtual Tour
      </div>
      <div
        className={classNames(
          classes.link,
          currentPage === 4 ? classes.active : ""
        )}
        onClick={() => setPage(4)}
        id="don"
      >
        Don's Photography
      </div>
      <div
        className={classNames(
          classes.link,
          currentPage === 3 ? classes.active : ""
        )}
        onClick={() => setPage(3)}
        id="contact"
      >
        Contact Us
      </div>

      <div className={classes.mobileMenu}>
        <MenuIcon onClick={handleOpen} />
        <Menu
          id="simple-menu"
          anchorEl={anchorEl}
          keepMounted
          open={Boolean(anchorEl)}
          onClose={handleClose}
        >
          <MenuItem onClick={() => setPage(0)}>Home</MenuItem>
          <MenuItem onClick={() => setPage(1)}>About</MenuItem>
          <MenuItem onClick={() => setPage(2)}>For Artists</MenuItem>
          <MenuItem onClick={() => setPage(6)}>Inspiration</MenuItem>
          <MenuItem onClick={() => setPage(5)}>Virtual Tour</MenuItem>
          <MenuItem onClick={() => setPage(4)}>Don's Photography</MenuItem>
          <MenuItem onClick={() => setPage(3)}>Contact Us</MenuItem>
        </Menu>
      </div>
      <div className={classes.facebookLink}>
        <a href="https://www.facebook.com/CorbyFraming" target="_blank">
          <FacebookIcon fontSize="large" />
          {/* <LeftArrow viewBox={'0 0 25 100'} style={{ width: 25, height: 100, stroke: 'white', fill: '#666' }} /> */}
        </a>
      </div>
      <div className={classes.emailLink}>
        <a href="mailto:info@corbyframing.ca">
          <EmailIcon fontSize="large" />
          {/* <LeftArrow viewBox={'0 0 25 100'} style={{ width: 25, height: 100, stroke: 'white', fill: '#666' }} /> */}
        </a>
      </div>
      {/* <div className={classes.facebookLink}><a href="https://www.facebook.com/CorbyFraming" target="_blank"><img src={`${FacebookIcon}`} className={classes.FBIcon}></img></a></div> */}
      {/* <Underline lineWidth={pages[currentPage].lineWidth} lineLeft={pages[currentPage].lineLeft} /> */}
    </div>
  );
}
