import React, { useState, useRef, useEffect } from 'react'
import { makeStyles, withTheme } from '@material-ui/core/styles'
import classNames from 'classnames'
import homeBackground from '../static/images/home3.png';
import Logo from '../components/Logo'
import Arrow from '../components/Arrow'
import { Link, Element, Events, animateScroll as scroll, scroller, scrollTo } from 'react-scroll'
// import RightNav from '../components/RightNav';
import argos from '../static/images/carousel/argos.png'
import flowers from '../static/images/carousel/flowers.png'
import kurt from '../static/images/carousel/kurt.png'
import masons from '../static/images/carousel/masons.png'
import medals from '../static/images/carousel/medals.png'
import soldier from '../static/images/carousel/soldier.png'
import necklace from '../static/images/carousel/necklace.png'
import oil from '../static/images/carousel/oil.png'
import owl from '../static/images/carousel/owl.png'
import joint from '../static/images/carousel/joint.png'
import spoon from '../static/images/carousel/spoon.png';
import frameshop_1 from '../static/images/carousel/frameshop_1.jpg'
import frameshop_2 from '../static/images/carousel/frameshop_2.jpg'
import frameshop_3 from '../static/images/carousel/frameshop_3.jpg'
import frameshop_4 from '../static/images/carousel/frameshop_4.jpg'
import frameshop_5 from '../static/images/carousel/frameshop_5.jpg'
import SlideShow from '../components/SlideShow'

const useStyles = makeStyles(theme => ({

    section: {
        width: '100vw',
        height: '100vh',
        zIndex: 10,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        alignItems:'center',
        justifyContent:'center',
    },
    home: {
    },
    homeBottom: {
        position: 'absolute',
        top: '50%',
        background: '#fff',
        height: '50vh',
        width: '100vw'
    },
    image: {
        position: 'absolute',
        width: '100vw',
        height: '50vh',
        backgroundImage: `url(${homeBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        transition: 'all 300ms easeInOut',
        opacity: .2,
        zIndex: 0,
        [theme.breakpoints.down('sm')]:{
            height:'40vh'
        }
    },
    logo: {
        position: 'relative',
         top: '10vh',
         height:'300px',
         width:'300px',
         [theme.breakpoints.down('sm')]:{
            top:'0vh',
            height:120,
            width:120
        }
    },
    tagline: {
        position: 'relative',
        top: '15vh',
        width:'60vw',
        fontWeight: 200,
        fontStyle: 'italic',
        fontSize: 30,
        color: theme.colors.lightBlue,
        [theme.breakpoints.down('sm')]:{
            width:'90vw',
            top:'5vh',
            fontSize:20
        }
    },
    arrow: {
        position: 'relative',
        top: '20vh',
        "& :hover": {
            cursor: 'pointer'
        },
        [theme.breakpoints.down('sm')]:{
            top:'15vh'
        }
    },

    carouselContainer: {
        background: theme.colors.lightBlue,
        borderTop:'5px solid '+ theme.colors.blue,
        borderBottom:'5px solid '+ theme.colors.blue,
        boxShadow: '0px 5px 10px 0px rgba(158,158,158,1)',
        marginTop:'5vh',
        height: '50vh',
        width: '100vw',
        position: 'relative',
        overflow:'hidden',
        whiteSpace:'no-wrap',
        display:'flex',
        zIndex: -10,
        "& img":{
            height:'90%',
            position:'relative',
            top:'0%',
            marginRight:'0vw',
            border:'5px solid '+theme.colors.blue
        },
        [theme.breakpoints.down('sm')]:{
            height:'90vh',
            "& img":{
                height:'20%'
            }
        }
    },
    imageDiv:{
        display: 'flex',
        flexDirection: 'row',
        justifyContent:'space-around',
        width: '100%',
        alignItems:'center',
        [theme.breakpoints.down('sm')]:{
            flexDirection:'column',
            height:'95%'
        }
        
    },
    carouselBannerText:{
        position:'absolute',
        background:'transparent',
        color:theme.colors.blue,
        top:0,
        left:'66vw',
        zIndex:20,
        height:'100%',
        width:'260px',
        "& span":{
            display:'block',
            position:'relative',
            width:'auto',
            fontWeight:'bold',
            padding:'2px 5px',
            fontSize:'1em',
            whiteSpace:'nowrap',
            [theme.breakpoints.down('sm')]:{
                fontSize:'.8em',
                color:theme.colors.light,
                textAlign:'center'
               }
        },
        [theme.breakpoints.down('sm')]:{
            width:'100%',
            left:0
        }
    },
    bannerTextOne:{
        left:0, 
        top:'17%',
        [theme.breakpoints.down('sm')]:{
            top:'30%',
            // left:'20%'
           
        }
    },
    bannerTextTwo:{left:'-11%', top:'38%', [theme.breakpoints.down('sm')]:{
        top:'57%',left:0
    }},
    bannerTextThree:{left:'-36%',top:'59%', [theme.breakpoints.down('sm')]:{
        top:'82%',left:0
    }},
    spacer:{
        height:5, 
        width:'100%', 
        background:theme.colors.blue,
        borderRadius:5,
        position:'absolute',
        [theme.breakpoints.down('sm')]:{
            display:'none !important'
        }
    },
    carouselBanner: {
        position: 'absolute',
        display: 'inline-block',
        padding: '1em 5em 1em 5em',
        color: '#fff',
        height:'50vh',
        left:'60vw',
        width:'140px',
        zIndex:10,
        top:0,
        opacity:0.85,
        "&::after": {
            content: '" "',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            height: '50vh',
            background: theme.colors.light,
            transformOrigin: '100% 0, 0 100%',
            transform: ' skew(-15deg)',
            zIndex: '-1',
            borderRight:'10px solid ' + theme.colors.blue,
            borderLeft:'10px solid ' + theme.colors.blue,
        },
        [theme.breakpoints.down('sm')]:{
            display:'none'
        }
    },
    fadingIn:{
        opacity:1,
        transition:'opacity 1.5s ease'
    },
    fadingOut:{
        opacity:0,
        transition:'opacity 1.5s ease'
    }
}))

function useIO(options) {
    const [ref, setRef] = useState(null);
    const [visible, setVisible] = useState(false);

    useEffect(() => {
        
        const observer = new IntersectionObserver(([entry]) => {
            setVisible(entry.isIntersecting);
        }, {})

        if (ref) {
            observer.observe(ref)
        }
        return () => {
            if (ref) {
                observer.unobserve(ref)
            }
        }
    }, [ref, options]);
    return [setRef, visible]
}
function getRandom(min,max){
    return Math.floor(Math.random() * (max - min + 1) + min);
}


export default function Home(props) {
    const classes = useStyles();
    const logoSize = window.innerWidth>959?300:150;
    const allBannerImages = [argos,flowers ,kurt,masons,medals,soldier ,necklace,oil,owl ,spoon,frameshop_1,frameshop_2,frameshop_3,frameshop_4,frameshop_5]
    const [fadeIn, setFadeIn] = useState(false)
    const [bannerImages, setBannerImages]  = useState([]);
    const [init, setInit]  = useState(false)
    const sections = [
        { name: 'top', callback: null },
        { name: 'one', callback: null },

    ]
    const [currentSection, setCurrentSection] = useState(0)
    const [setRefZero, refZeroVisible] = useIO({});
    const [setRefOne, refOneVisible] = useIO({});
    const [setRefTwo, refTwoVisible] = useIO({});
    // const [logoSize, setLogoSize] = useState(300)
    
    let keyPressed = false;

    const arrowClick = () => {
        scroll(sections[1].name, null);
        setCurrentSection(1)
    }

    function scroll(element, callback) {
        scroller.scrollTo(element, {
            duration: 600,
            delay: 0,
            smooth: 'cubic-bezier(0,.9,0,1.04)',
        })

        if (callback != null) {

            setTimeout(() => {
                callback()
            }, 600)
        }
    }
    function upHandler() {
        keyPressed = false;
    }

    function downHandler({ key }) {
        if (!keyPressed) {
            keyPressed = true;
            if (key === 'ArrowDown' && (currentSection + 1) < sections.length) {
                scroll(sections[currentSection + 1].name, sections[currentSection + 1].callBack)
                setCurrentSection(currentSection + 1)
            } else if (key === 'ArrowUp' && (currentSection > 0)) {
                scroll(sections[currentSection - 1].name, sections[currentSection - 1].callBack)
                setCurrentSection(currentSection - 1)
            }
        }
    }

    useEffect(() => {
        window.addEventListener('keydown', downHandler);
        window.addEventListener('keyup', upHandler);
        return () => {
            window.removeEventListener('keydown', downHandler);
            window.removeEventListener('keyup', upHandler);
            // window.removeEventListener('wheel', mouseWheelHandler);
        };

    })

    useEffect(()=>{
        let unmounted = false;
        if(!unmounted){
            setBannerImages([allBannerImages[0],allBannerImages[1],allBannerImages[2]])
            setFadeIn(true)
            setInit(true)
        }
      
       return ()=>{
          unmounted = true
       }
    },[])

    useEffect(()=>{
       let unmounted=false;
       let x;
       if(!unmounted){
        x = setInterval(()=>{
            let randomNumberSet = [];
            while(randomNumberSet.length<3){
                let rando = getRandom(0,allBannerImages.length-1);
                if(randomNumberSet.indexOf(rando) === -1) randomNumberSet.push(rando);
            }
            
            setFadeIn(false);
            setTimeout(()=>{
                setBannerImages([allBannerImages[randomNumberSet[0]],allBannerImages[randomNumberSet[1]],allBannerImages[randomNumberSet[2]]])
                setTimeout(()=>{
                    setFadeIn(true)},500)
            },1500)           
            
        },8000)
       }
       
        return ()=>{
            unmounted = true;
            clearInterval(x)
        }
    },[])

    return (
        <div >
            <div id="image" className={classes.image}></div>
            {/* <div className={classes.rightNav}><RightNav navigate={rightNav} current={currentSection} /></div> */}
            <Element name="top">
                <div className={classNames(classes.home, classes.section)} ref={setRefZero} id="0">
                    <div className={classes.logo}><Logo viewBox={'0 0 300 300'} style={{height:logoSize,width:logoSize,color:'#002366'}} /></div>
                    <div className={classes.tagline}> 
                        <p>With 30 years experience in the framing business, Don Corby provides all of his customers excellence in craftsmanship while
                            passing his personal consideration of cost on to his customers.
                        </p>
                        </div>
                    <div className={classes.arrow} onClick={() => arrowClick()}><Arrow /></div>
                </div>
            </Element>
            <Element name="one">
                <div className={classNames(classes.section)} id="one">
                    <div className={classes.carouselContainer}>
                        <div className={classes.carouselBanner}></div>
                        <div className={classes.carouselBannerText}>
                            <span className={classes.bannerTextOne}>Top Quality Materials</span>
                            <span className={classes.spacer} style={{top:'26%',left:'-10%'}}></span>
                            <span className={classes.bannerTextTwo}>Fast Turnaround</span>
                            <span className={classes.spacer} style={{top:'49%',left:'-28%'}}></span>
                            <span className={classes.bannerTextThree}>Satisfaction Guaranteed</span>
                        </div>

                        <div className={classes.imageDiv}>
                            
                                {
                                    bannerImages.map(image=>{
                                        return (<img src={`${image}`} key={image} className={fadeIn?classes.fadingIn:classes.fadingOut}/>
                                        )
                                        
                                    })
                                }
                        </div>

                    </div>
                   
                </div>
            </Element>

            <footer>Site designed and developed by <a href="http://byroncpeterson.com" target="_blank">Byron Peterson</a></footer>
        </div>
    )
}
