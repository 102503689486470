import React, { useState, useEffect } from "react";
import { makeStyles } from "@material-ui/core";
import classNames from "classnames";
import History from "../components/History";
import Sports from "../components/Sports";
import Arts from "../components/Arts";
import Memories from "../components/Memories";
import Awards from "../components/Awards";
// import { gsap, TimelineLite, Power3 } from 'gsap';

const useStyles = makeStyles((theme) => ({
  appendedImage: {
    background: "red",
  },
  inspirationContainer: {
    height: "100vh",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
  },
  inspirationHeading: {
    // position: "absolute",
    marginTop: "1rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    fontSize: "3rem",
    padding: "0 10rem",
    [theme.breakpoints.down("xl")]: {
      fontSize: "2rem",
    },
    [theme.breakpoints.down("lg")]: {
      fontSize: "1.6rem",
      // marginTop: "0rem",
      width: "80%",
      "& * ul": {
        marginTop: "1rem",
      },
    },
    [`${theme.breakpoints.down("lg")} and (orientation:landscape)`]: {
      marginTop: "3rem",
    },
    [theme.breakpoints.down("md")]: {
      fontSize: "1rem",
      top: "6rem",
      width: "90%",
      "& * ul": {
        marginTop: 0,
      },
    },
    [theme.breakpoints.down("sm")]: {
      top: "3rem",
      width: "95%",
    },
    [theme.breakpoints.down("xs")]: {
      marginTop: "3rem",
      width: "95%",
    },

    [`${theme.breakpoints.down("sm")} and (orientation:landscape)`]: {
      top: "3rem",
      width: "75%",
      padding: "0 0",
      left: "5%",
      fontSize: ".7rem",
    },
    [`${theme.breakpoints.down("xs")} and (orientation:landscape)`]: {
      marginTop: "2rem",
      width: "95%",
    },
  },
  inspirationLinks: {
    marginTop: "1rem",
    [theme.breakpoints.up("lg")]: {
      marginTop: 0,
    },
    [`${theme.breakpoints.down("sm")} and (orientation:landscape)`]: {
      marginTop: 0,
    },
    [`${theme.breakpoints.down("xs")} and (orientation:landscape)`]: {
      marginTop: "1rem",
    },
    "& ul": {
      listStyleType: "none",
      "& li": {
        display: "inline-block",
        marginRight: "1em",
      },
    },
  },
  inspirationLink: {},
  activeLink: {
    borderBottom: "2px solid",
  },
  imageDisplaySection: {
    width: "100%",
    height: "auto",
    // background: "blue",
  },
}));

export default function Inspiration() {
  const [currentSection, setCurrentSection] = useState("history");

  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
    landscape: window.orientation === 0 ? false : true,
    breakPointColumns:
      window.innerWidth < 500
        ? 1
        : window.innerWidth < 737
        ? 3
        : window.innerWidth < 1100
        ? 5
        : 5,
    numberOfItems:
      window.innerWidth < 500 ? 1 : window.innerWidth < 770 ? 3 : 4,
  });

  const classes = useStyles();
  const changeSection = (dest) => {
    setCurrentSection(dest);
  };
  const getSection = () => {
    switch (currentSection) {
      case "history":
        return <History numberOfImagesToDisplay={dimensions.numberOfItems} />;
      case "sports":
        return <Sports numberOfImagesToDisplay={dimensions.numberOfItems} />;
      case "arts":
        return <Arts numberOfImagesToDisplay={dimensions.numberOfItems} />;
      case "awards":
        return <Awards numberOfImagesToDisplay={dimensions.numberOfItems} />;
      case "memories":
        return <Memories numberOfImagesToDisplay={dimensions.numberOfItems} />;
    }
  };
  return (
    <div>
      <div className={classes.inspirationContainer}>
        <section className={classNames(classes.inspirationHeading)}>
          <span>
            If you need inspiration for your next framing project, check out
            some of the work done at Corby's, utilizing shadowboxes to display
            your precious memories...
          </span>
          <div className={classes.inspirationLinks}>
            <ul>
              <li
                className={classNames(
                  classes.inspirationLink,
                  currentSection === "history" ? classes.activeLink : ""
                )}
              >
                <a onClick={() => changeSection("history", 33)}>History</a>
              </li>
              <li
                className={classNames(
                  classes.inspirationLink,
                  currentSection === "sports" ? classes.activeLink : ""
                )}
              >
                <a onClick={() => changeSection("sports", 33)}>Sports</a>
              </li>

              <li
                className={classNames(
                  classes.inspirationLink,
                  currentSection === "awards" ? classes.activeLink : ""
                )}
              >
                <a onClick={() => changeSection("awards", 20)}>Awards</a>
              </li>

              <li
                className={classNames(
                  classes.inspirationLink,
                  currentSection === "memories" ? classes.activeLink : ""
                )}
              >
                <a onClick={() => changeSection("memories", 17)}>Memories</a>
              </li>
              <li
                className={classNames(
                  classes.inspirationLink,
                  currentSection === "arts" ? classes.activeLink : ""
                )}
              >
                <a onClick={() => changeSection("arts", 26)}>Arts & Crafts</a>
              </li>
            </ul>
          </div>
        </section>
        <div className={classes.imageDisplaySection}>{getSection()}</div>
      </div>
    </div>
  );
}
