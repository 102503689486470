import React from 'react'
import SvgIcon from '@material-ui/core/SvgIcon';

export default function Logo(props) {
    return (
        <SvgIcon {...props}>
            <rect width="300" height="300" style={{ fill: 'none', strokeWidth: 10,stroke:props.style.color}}></rect>
            <rect width="250" height="250" x="25" y="25" style={{
                 fill: 'rgba(255,255,255,.3)', 
                 strokeWidth: 25, 
                 stroke:props.style.color

                //  stroke: props.style.color 
                 }}></rect>

            <text x="16%" y="40%" fill="red'" style={{
                strokeWidth: 0.55, fontFamily: 'Montserrat', fontSize: 66, fontWeight: 700, fontStyle: 'bold', fontStretch: 'normal',
                fontVariant: 'normal', stroke: 'none', strokeOpacity: 1, strokeMiterlimit: 4, strokeDasharray: 'none', fill: props.style.color
            }}>Corby</text>
            <text x="20%" y="60%" fill="none'" style={{
                strokeWidth: 0.55, fontFamily: 'Montserrat', fontSize: 46, fontWeight: 400, fontStyle: 'bold', fontStretch: 'normal',
                fontVariant: 'normal', stroke: 'none', strokeOpacity: 1, strokeMiterlimit: 4, strokeDasharray: 'none', fill: props.style.color
            }}>custom</text>
            <text x="18%" y="80%" fill="none'" style={{
                strokeWidth: 0.55, fontFamily: 'Montserrat', fontSize: 46, fontWeight: 400, fontStyle: 'bold', fontStretch: 'normal',
                fontVariant: 'normal', stroke: 'none', strokeOpacity: 1, strokeMiterlimit: 4, strokeDasharray: 'none', fill: props.style.color
            }}>framing</text>
        </SvgIcon>
    )
}
