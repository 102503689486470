import React, { useState, useEffect } from 'react'
import { makeStyles } from '@material-ui/core'
import classNames from 'classnames'
import UseInterval from '../utils/UseInterval'

const useStyles = makeStyles(theme => ({
    slides: {
        opacity: 1,
        transition: 'opacity 2s cubic-bezier(.58,.1,.34,.99)'
    },
    fading: {
        opacity: 0,
        transition: 'opacity 2s cubic-bezier(.58,.1,.34,.99)'
    }

}))


export default function SlideShow(props) {
    const classes = useStyles()
    const [currentSlide, setCurrentSlide] = useState(props.slides[0])
    const [isFading, setIsFading] = useState(false)
    let count = 0;

    useEffect(() => {
        setInterval(() => {
            count += 1;
            if (count == props.slides.length) {
                count = 0
            }
            setIsFading(true)
            setTimeout(() => {
                setCurrentSlide(props.slides[count])
                setTimeout(() => {
                    setIsFading(false)
                }, 200)

            }, 2000)


        }, 10000 + props.offset)
    }, [])
    return (


        <div className={classNames(isFading ? classes.fading : classes.slides)}>
            <img src={require(`../static/images/carousel/${currentSlide}`)} key={currentSlide} alt="" style={{ minHeight: 300, height: 300 }} />
        </div>
    )

}
