import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import IconButton from '@material-ui/core/IconButton';
import LeftArrow from '../components/LeftArrow';
import RightArrow from '../components/RightArrow';
import classNames from 'classnames';


const useStyles = makeStyles(theme => ({
    contentContainer: {
        minHeight: '100%',
        height: 'auto',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start',
        flexDirection: 'row',
        paddingTop:'15vh',
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            paddingTop: '15vh',
            flexDirection: 'column',
            paddingBottom:'6vh'
        }
    },
    intro: {
        width: '40vw',
        marginRight: '5vw',
        position: 'absolute',
        left: '5vw',
        [theme.breakpoints.down('sm')]: {
            width: '90vw',
            position: 'relative',
            left: '4vw',
            marginRight: 0,
            fontSize: '.9em'
        },
        [theme.breakpoints.up('md')]:{
            fontSize:'.9em'
        }
    },
    imageDetail: {
        boxShadow: '9px 10px 14px 3px rgba(177,179,194,0.4)',
        border: '1px solid ' + theme.colors.blue,
        position: 'absolute',
        left: '50vw',
        [theme.breakpoints.down('sm')]: {
            position: 'relative',
            left: '4vw',
            width:'90vw',
            paddingBottom:0,
            marginBottom:'100px',
            "& img":{
                width:'100%'
            }
        },
        [theme.breakpoints.up('sm')]: {
            // top: '27vh',
            maxWidth:'90vw',
            "& img":{
                width:'100%'
            }
        },
        [theme.breakpoints.up('md')]:{
            // height:'45vh',
            marginRight:'3vw',
            "& img":{
                height:'100%'
            }
        }
    },
    carousel: {
        [theme.breakpoints.down('sm')]: {
            position: 'absolute'
        }
    },
    thumbs: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        width: '100%',
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
        height: 'auto',
        transition: 'left .5s ease-in-out',
        "& img": {
            objectFit: 'cover',
            height: 150,
            width: 150,
            filter: 'grayscale(100%) brightness(150%)',
            
            [theme.breakpoints.down('sm')]: {
                height: 75,
                width: 75
            },
            [theme.breakpoints.between('sm','lg')]:{
                height:100,
                width:100
            }
        },
        "& :hover":{
            "& img":{
                filter:'none'
            }
            
        },
    },
    thumbImageContainer: {
        height: 170,
        maxWidth: 170,
        minWidth: 170,
        padding: 10,
        position: 'relative',
        top: 0,
        left: 0,
        background: theme.colors.blue,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        alignContent: 'center',
        [theme.breakpoints.down('sm')]: {
            height: 85,
            width: 85,
            maxWidth: 85,
            minWidth: 85,
        },
        [theme.breakpoints.between('sm','lg')]:{
            height: 100,
            width: 100,
            maxWidth: 100,
            minWidth: 100,
        }
    },
    thumbOverlay: {
        minHeight: 190,
        minWidth: 190,
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'rgba(0,0,0,.5)',
        zIndex: 10,
        [theme.breakpoints.down('sm')]: {
            minHeight: 105,
            minWidth: 105,
        },
        [theme.breakpoints.between('sm','lg')]:{
            height: 120,
            width: 120,
            maxWidth: 120,
            minWidth: 120,
        }
    },
    hidden: { display: 'none' },
    unfiltered:{
        "& img":{
            filter:'none'
        }
    },
    leftArrow: {
        position: 'fixed',
        bottom: 0,
        left: 0,
        zIndex: 20,
        background: 'rgba(0,0,0,.8)',
        height: 190,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            height:105,
            width:55
        },
        [theme.breakpoints.between('sm','lg')]:{
            height: 120,
            width: 60,
            maxWidth: 60,
            minWidth: 60,
        }
    },
    rightArrow: {
        position: 'fixed',
        bottom: 0,
        right: 0,
        zIndex: 20,
        background: 'rgba(0,0,0,.8)',
        height: 190,
        display: 'flex',
        [theme.breakpoints.down('sm')]: {
            height:105,
            width:55
        },
        [theme.breakpoints.between('sm','lg')]:{
            height: 120,
            width: 60,
            maxWidth: 60,
            minWidth: 60,
        }
    },
    arrowFade:{
        opacity:0.1,
    },
    fadeOut:{
        opacity:0,
        transition:'opacity .5s ease-in-out'
    },
    fadeIn:{
        opacity:1,
        transition:'opacity .5s ease-in-out'
    }

}))
export default function Photography() {
    const classes = useStyles();
    // const viewportWidthRatio = window.innerWidth/1920;
    const availableImages = [
        { id: 0, path: "yellow-ladys-slipper-pair-100603.jpg" },
        { id: 1, path: "12-spotted-dragonfly-1.jpg" },
        { id: 2, path: "aven-prairie-smoke.jpg" },
        { id: 3, path: "bleeding-hearts-1201.jpg" },
        { id: 4, path: "california-poppy.jpg" },
        { id: 5, path: "cherry-tree.jpg" },
        { id: 6, path: "clover.jpg" },
        { id: 7, path: "coneflowed-trio.jpg" },
        { id: 8, path: "fiddlehead.jpg" },
        { id: 9, path: "gage-petal-4.jpg" },
        { id: 10, path: "hepatica-pair.jpg" },
        { id: 11, path: "monarch-on-black-eyed-susan-copy.jpg" },
        { id: 12, path: "p1010972.jpg" },
        { id: 13, path: "petunia.jpg" },
        { id: 14, path: "purple-and-yellow-iris.jpg" },
        { id: 15, path: "red-tulip.jpg" },
        { id: 16, path: "scan_0004.jpg" },
        { id: 17, path: "scan_0008.jpg" },
        { id: 18, path: "scan_0037.jpg" },
        { id: 19, path: "sunflower-6.jpg" },
        { id: 20, path: "wet-leaf-on-vine.jpg" },
        { id: 21, path: "white-trillium-plant.jpg" },
        { id: 22, path: "yellow-lady-slipper.jpg" },

    ]
    const [currentSliderPosition, setCurrentSliderPosition] = useState(10)
    const [selectedImage, setSelectedImage] = useState(0)
    const [isFadingOut, setIsFadingOut] = useState(false)
    const [rightArrowFade, setRightArrowFade] = useState(false);
    const [leftArrowFade, setLeftArrowFade] = useState(false);
    const [windowRatio, setWindowRatio] = useState(0);
    const loadImage = imageID => {
        const imageDetail =  document.getElementById('imageDetail')
       setIsFadingOut(true)
        setTimeout(()=>{
            setSelectedImage(imageID);
            setIsFadingOut(false)
        },600);
       
    }
    const handleClick = direction => {

        const windowWidth = window.innerWidth;
        const imageSize = windowWidth>1881?190:windowWidth>601?120:105;
        const imagesOnScreen = Math.round(windowWidth/imageSize);
       
        const numberImages = availableImages.length;
        
        const maxRight = (numberImages-imagesOnScreen)*imageSize
        const em = document.getElementById('thumbList')
        const leftStyle = em.style.left;
        let left;
        const plusMinus = direction === 'right' ? -1 : 1;

        if (leftStyle.indexOf('px') > -1) {
            left = parseInt(leftStyle.substr(0, leftStyle.indexOf('px')))
        } else {
            left = 0;
        }

        if (direction === 'right') {
            if (currentSliderPosition < availableImages.length-imagesOnScreen) {
                left= left-imageSize*5;
                setCurrentSliderPosition(currentSliderPosition + 5 );
                setLeftArrowFade(false)
            }else{
                left=-1*maxRight - imageSize/2;
                setCurrentSliderPosition(availableImages.length)
                setRightArrowFade(true)
            }
            em.style.left = left+'px'
        } else {
            if (currentSliderPosition > imagesOnScreen) {
                left = Math.min(left + imageSize*5, 0)
                em.style.left = left + 'px';
                if(currentSliderPosition - 5 <11) setLeftArrowFade(true)
                setCurrentSliderPosition(currentSliderPosition - 5 );
                setRightArrowFade(false)
            }
        }

    }
    return (
        <div className={classes.contentContainer}>
            <div className={classes.intro}>
                <p>
                    Don specializes in macro botanical photography and holds annual workshops instructing others on the intricacies of macro photography.
                    He has been specializing in macro photography for more than 20 years now.
            </p>
                <p>He continues to capture his images on transparency film and has no need or ambition to change over to digital imaging in the future. The natural colours and subtle transitions that are reproduced on transparency film is a perfect match for Don’s style of photography.</p>
                <p>In 2012, Don self-published a book highlighting his favourite macro images. The book went to second print in 2013.</p>
            </div>

            <div className={classNames(classes.imageDetail,isFadingOut?classes.fadeOut:classes.fadeIn)} id="imageDetail">
                <img src={require('../static/images/don_photos/' + availableImages[selectedImage].path)} />
            </div>
            <div className={classes.carousel}>
                <div className={classNames(classes.leftArrow,leftArrowFade?classes.arrowFade:'')}>
                    <IconButton color="primary" aria-label="left nav" component="span" onClick={() => handleClick('left')}>
                        <LeftArrow viewBox={'0 0 25 100'} style={{ width: 25, height: 100, stroke: 'white', fill: '#666' }} />
                    </IconButton>
                </div>
                <div className={classes.thumbs} id='thumbList'>
                    {availableImages.map(image => {
                        let theImage = require('../static/images/don_photos/' + image.path);

                        return (
                            <div key={image.id} style={{ position: 'relative' }} onClick={() => loadImage(image.id)}>
                                <div className={selectedImage != image.id ? classes.thumbOverlay : classes.hidden}></div>
                                <div className={classNames(classes.thumbImageContainer, selectedImage==image.id?classes.unfiltered:'')} >
                                    <img src={require('../static/images/don_photos/' + image.path)} />
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className={classNames(classes.rightArrow,rightArrowFade?classes.arrowFade:'')}>
                    <IconButton color="primary" aria-label="left nav" component="span" onClick={() => handleClick('right')}>
                        <RightArrow viewBox={'0 0 25 100'} style={{ width: 25, height: 100, stroke: 'white', fill: '#666' }} />
                    </IconButton>
                </div>
            </div>
        </div>
    )
}