import React, { useState, useEffect, createRef } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import { Button, InputLabel, TextField } from '@material-ui/core'
const useStyles = makeStyles(theme => ({
    contentContainer: {
        width: '90vw',
        height: 'auto',
        display: 'flex',
        flexDirection: 'row',
        alignContent: 'center',
        alignItems: 'flex-start',
        margin: '0 auto',
        [theme.breakpoints.down('md')]: {
            width: '95vw',
            flexDirection: 'column',
            fontSize: '0.9em'
        }
    },
    leftContainer: {
        width: '50%',
        border: '1px solid ' + theme.colors.blue,
        borderRadius: 5,
        minHeight: '70vh',
        marginTop: '15vh',
        marginRight: 15,
        boxShadow: '9px 10px 14px 3px rgba(177,179,194,0.2)',
        marginBottom: 20,
        padding: '20px 60px',
        fontSize: '.9em',
        lineHeight: '22px',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            width:'100%'
        },
        [theme.breakpoints.down('sm')]: {
            marginTop: '18vh',
            marginBottom: 10
        }
    },
    rightContainer: {
        width: '50%',
        border: '1px solid ' + theme.colors.blue,
        borderRadius: 5,
        minHeight: '70vh',
        marginTop: '15vh',
        boxShadow: '9px 10px 14px 3px rgba(177,179,194,0.2)',
        marginBottom: 20,
        padding: '20px 60px',
        fontSize: '.9em',
        lineHeight: '22px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'flex-start',
        alignItems: 'center',
        boxSizing: 'border-box',
        [theme.breakpoints.down('md')]: {
            width:'100%'
        }
    },
    heading: {
        borderBottom: '1px solid #000',
        textAlign: 'center',
        paddingBottom: 15
    },
    emailForm: {
        display: 'flex',
        flexDirection: 'column',
        alignContent: 'center',
        alignItems: 'center',
        justifyContent: 'center',
        paddingTop: 40,
        width: '80%'
    },
    root: {
        width: '100%'
    },
    input: {
        color: theme.colors.lightBlue,
        [theme.breakpoints.up('sm')]: { width: '100%' },
        [theme.breakpoints.down('sm')]: { width: '100%' },
        '&:after': {
            borderBottom: '1px solid',
            borderBottomColor: theme.colors.lightBlue
        },
        "&:before": {
            borderBottom: '1px solid',
            borderBottomColor: theme.colors.lightBlue
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderBottom: '1px solid',
            borderBottomColor: theme.colors.lightBlue
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
            borderBottom: '1px solid',
            borderBottomColor: theme.colors.lightBlue
        },
        "& input": {
            width: '500px'
        }
    },
    submit: {
        alignSelf: 'end',
        background: theme.colors.blue,
        color: theme.colors.light,
        marginTop: 10,
        "&:hover": {
            background: theme.colors.lightBlue
        }
    }
}))

export default function Contact() {
    const classes = useStyles();
    let googleMapRef = createRef();
    let googleMap;
    let marker;
    const [state, setValues] = useState({
        fullname: '',
        email: '',
        subject: '',
        inquiry: ''
    })
    const googleMapScript = document.createElement('script');
    const GOOGLE_MAP_API_KEY = 'AIzaSyA70UexP1txuxv-kA3rlKiwYTcqYIt3COg'
    googleMapScript.src = `https://maps.googleapis.com/maps/api/js?key=${GOOGLE_MAP_API_KEY}`;

    window.document.body.appendChild(googleMapScript);
    const createMap = ()=>{
        googleMap = createGoogleMap();
        marker = createMarker();
    }
    useEffect(() => {
        googleMapScript.addEventListener('load', createMap);
        // window.addEventListener('keyup', upHandler);
        return () => {
            googleMapScript.removeEventListener('load', createMap);
            // window.removeEventListener('keyup', upHandler);
            // window.removeEventListener('wheel', mouseWheelHandler);
        };

    }, [])
    const createGoogleMap = () =>
        new window.google.maps.Map(googleMapRef.current, {
            zoom: 11,
            center: {
                lat:43.3426946,
                lng: -79.9357963,
            },
            disableDefaultUI: false,
        });
    const createMarker = () =>
        new window.google.maps.Marker({
            position: { lat: 43.3777089, lng:-80.0020038},
            label:'C',
            map: googleMap,
        });
    // const handleChange = prop => event => {
    //     setValues({ ...state, [prop]: event.target.value });
    // };
    const getMap = () => {
        return (
            <div
                id="google-map"
                ref={googleMapRef}
                style={{ width: '100%', height: '600px' }}
            />
        )
    }
    return (
        <div className={classes.contentContainer}>
            <div className={classes.leftContainer}>
                <p>For Gallery hours, pricing and anything else you want to ask about framing, please contact us.</p>
                <p>By appointment or by chance we are open most days – check for hours before you come to avoid disappointment</p>
                <p>Visit our gallery and enjoy friendly service in a relaxed country setting.</p>
                <p>We are located in rural Flamborough at:</p>
                <p>26 Carlisle Road, Freelton, Ontario, L0R 1K0</p>
                <p><b>Phone</b>: (905)689-1976</p>
                <p><b>Tollfree</b>: 1(877)689-1976</p>
                <p><b>E-mail</b>: <a href="mailto:info@corbyframing.ca">info@corbyframing.ca</a></p>

                <p><b>Hours</b>:</p>
                <p>Tuesday to Friday  9:00 am – 5:00 pm</p>
                <p>Saturday: 9:00 am – 3:00 pm</p>
                <p>Sunday & Monday -  Closed</p>
                <p>Just in from highway 6, we are 10 minutes south of the 401 or 10 minutes north of the 403 Hamilton.</p>
                <p>Corby Custom Framing is a 40 minute drive from Toronto, a 20 minute drive from Hamilton, Burlington, Guelph and Cambridge, and a 10 minute drive from Waterdown and Dundas</p>
            </div>
            <div className={classes.rightContainer}>
                {getMap()}

                {/* <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d2899.96724728933!2d-80.00419248451118!3d43.37770887913171!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x882b7ddbec6984fb%3A0x2c303a4cb94e8f0a!2s26%20Carlisle%20Rd%2C%20Freelton%2C%20ON%20L0R%201K0!5e0!3m2!1sen!2sca!4v1589636034614!5m2!1sen!2sca" width="600" height="450" frameborder="0" style="border:0;" allowfullscreen="" aria-hidden="false" tabindex="0"></iframe> */}
            </div>

        </div>
    )
}
