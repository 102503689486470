import React from 'react'

export default function Arrow() {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="100.854" height="34.854" viewBox="0 0 100.854 34.854">
            <g id="Component_5_1" data-name="Component 5 – 1" transform="translate(5.427 5.427)">
                <line id="Line_2" data-name="Line 2" x2="44" y2="24" fill="none" stroke="#544224" strokeLinecap="round" strokeWidth="8" />
                <line id="Line_3" data-name="Line 3" y1="24" x2="44" transform="translate(46)" fill="none" stroke="#544224" strokeLinecap="round" strokeWidth="8" />
            </g>
        </svg>
    )
}
