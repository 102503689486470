import React, { useState, useRef, useEffect } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import Logo from '../components/Logo'
import homeBackground from '../static/images/home3.png';
import { Link, Element, Events, animateScroll as scroll, scroller, scrollTo } from 'react-scroll';

const useStyles = makeStyles(theme=>({
    contentSection:{
        minHeight:'100vh',
        minWidth:'100vw',
        // position:'absolute',
        // top:0,
        display:'flex',
        justifyContent:'center',
        alignItems:'center',
        flexDirection:'column',
    },
    top:{
        position:'absolute',
        top:0
    },
    image: {
        // position: 'absolute',
        width: '100vw',
        height: '50vh',
        backgroundImage: `url(${homeBackground})`,
        backgroundPosition: 'center',
        backgroundSize: 'cover',
        backgroundRepeat: 'no-repeat',
        transition: 'all 300ms easeInOut',
        opacity: .2,
        zIndex: 0
    },
    logo:{
      
    },
    item:{
        display:'flex',
        maxWidth:'50vw',
        alignContent:'left',
        textAlign:'left'
    }
}))
export default function Landing() {
const classes = useStyles();
const [currentSection, setCurrentSection] = useState(0);
let keyPressed = false;
const sections = [
    { name: 'top', callback: null },
    { name: 'one', callback: null },
]
function scroll(element, callback) {
    scroller.scrollTo(element, {
        duration: 800,
        delay: 0,
        smooth: 'easeInOutQuad',
    })

    if (callback != null) {

        setTimeout(() => {
            callback()
        }, 600)
    }
}
function upHandler() {
    keyPressed = false;
}

function downHandler({ key }) {
    if (!keyPressed) {
        keyPressed = true;
        if (key === 'ArrowDown' && (currentSection + 1) < sections.length) {
            scroll(sections[currentSection + 1].name, sections[currentSection + 1].callBack)
            setCurrentSection(currentSection + 1)
        } else if (key === 'ArrowUp' && (currentSection > 0)) {
            scroll(sections[currentSection - 1].name, sections[currentSection - 1].callBack)
            setCurrentSection(currentSection - 1)
        }
    }
}
useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);
    return () => {
        window.removeEventListener('keydown', downHandler);
        window.removeEventListener('keyup', upHandler);
        // window.removeEventListener('wheel', mouseWheelHandler);
    };
})
    return (
        <div>
            <div id="image" className={classes.image}></div>
            <Element name="top">
            <div className={classNames(classes.contentSection, classes.top)}>
                <div className={classNames(classes.logo,classes.item)}>
                    <Logo />
                </div>
                <div className={classes.item}>
                    <p>With 30 years experience in the framing business, Don Corby provides all of his customers excellence in craftsmanship while 
                    passing his personal consideration of cost on to his customers.
                     </p>
                </div>
               
            </div>
            </Element>
            <Element name="one">
            <div className={classes.contentSection}>
                <div className={classNames(classes.logo,classes.item)}>
                    <Logo />
                </div>
                <div className={classes.item}>
                    <p>With 30 years experience in the framing business, Don Corby provides all of his customers excellence in craftsmanship while 
                    passing his personal consideration of cost on to his customers.
                     </p>
                </div>
               
            </div>
            </Element>
        </div>
    )
}
