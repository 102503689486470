import React from 'react'
import { makeStyles } from '@material-ui/core/styles'

const useStyles = makeStyles(theme => ({
    tourContent:{
        display:'flex',
        justifyContent:'center',
        flexDirection:'column',
        position:'relative',
        top:100,
        alignItems:'center',
        [theme.breakpoints.down('md')]:{
            fontSize:'.9em'
        }
    },
    tourFrame:{
        height:600,
        width:800,
        [theme.breakpoints.down('md')]:{
            height:300,
            width:300
        }
    }
}))

export default function Tour() {
    const classes = useStyles();
    return (
        <div className={classes.tourContent}>
            <h2 id="transition-modal-title">Corby framing studio</h2>
            <p id="transition-modal-description">
                <code>
                    <iframe src="https://www.google.com/maps/embed?pb=!4v1588592719885!6m8!1m7!1sCAoSLEFGMVFpcE11ay1mWTY3REdHcUlEOWd0ZWh6NWVEdVpkNTlybG4zWkFpUWlv!2m2!1d43.37778962586795!2d-80.00205275689353!3f131.63928549044172!4f-6.997982203243453!5f0.7820865974627469" className={classes.tourFrame}></iframe>
                </code>
            </p>
        </div>
    )
}
